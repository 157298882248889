import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import type {Theme} from '@mui/material'

export default {
  MuiAutocomplete: {
    defaultProps: {
      loadingText: 'Načítám...',
      noOptionsText: 'Žádné výsledky',
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: ({theme}) => ({
        boxShadow: 'none',
        borderColor: theme.palette.grey[400],
      }),
    },
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({theme}) => ({
        '&.Mui-expanded': {
          backgroundColor: theme.palette.background.default,
        },
      }),
    },
    defaultProps: {
      expandIcon: <ExpandMoreIcon />,
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({theme}) => ({
        padding: theme.spacing(2),
      }),
    },
  },
  MuiAccordionActions: {
    styleOverrides: {
      root: ({theme}) => ({
        padding: theme.spacing(2),
        gap: theme.spacing(1),
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      action: ({theme, ownerState: {severity}}) => ({
        '& .MuiIconButton-root': {
          color: severity && theme.palette[severity].contrastText,
          padding: 0,
        },
      }),
    },
    defaultProps: {
      severity: 'info',
      action: '',
      variant: 'filled',
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      },
    },
    defaultProps: {
      position: 'fixed',
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: ({theme}) => ({
        color: theme.palette.common.white,
        zIndex: 1,
      }),
    },
  },
  MuiBreadcrumbs: {
    defaultProps: {
      separator: '•',
    },
  },
  MuiButton: {
    styleOverrides: {
      containedError: ({theme}) => ({
        color: theme.palette.common.white,
      }),
    },
    defaultProps: {
      color: 'primary',
      variant: 'outlined',
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({theme}) => ({
        color: theme.palette.text.primary,
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({theme, ownerState: {color}}) => ({
        margin: '2px',
        borderRadius: '4px',
        backgroundColor:
          color && color !== 'default' ? theme.palette[color].light : undefined,
      }),
      colorError: ({theme}) => ({
        color: theme.palette.error.main,
      }),
    },
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '32px',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({theme}: {theme: Theme}) => ({
        padding: '32px !important',
        minWidth: '26rem',
        [theme.breakpoints.down('sm')]: {
          minWidth: '10rem',
        },
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: ({theme}: {theme: Theme}) => ({
        backgroundColor: theme.palette.background.default,
        padding: '26px 32px',
        fontSize: '18px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      }),
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paperAnchorDockedLeft: {
        borderRight: 'none',
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      sizeSmall: {
        minWidth: '35px',
        width: '35px',
        height: '35px',
      },
    },
    defaultProps: {
      color: 'primary',
      size: 'small',
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({theme}) => ({
        color: theme.palette.grey[600],
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({theme}) => ({
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(1),
          padding: '0',
          maxWidth: '3rem',
          maxHeight: '3rem',
        },
      }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        overflow: 'visible',
      },
    },
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'none',
      color: 'primary',
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingTop: '12px',
        paddingRight: '16px',
        paddingBottom: '12px',
        paddingLeft: '16px',

        '& .MuiSvgIcon-root': {
          marginRight: '16px',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        boxShadow: '0 0 24px rgba(145, 158, 175, 0.17)',
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({theme}) => ({
        width: 33,
        height: 20,
        padding: 0,
        marginRight: 13,
        marginLeft: 11,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 3,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(13px)',
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.success.main,
              opacity: 1,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[300],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
        },
        '& .MuiSwitch-thumb': {
          width: 14,
          height: 14,
          boxShadow: 'unset',
        },
        '& .MuiSwitch-track': {
          borderRadius: 20 / 2,
          backgroundColor: theme.palette.text.secondary,
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }),
    },
    defaultProps: {
      color: 'primary',
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottomColor: '#DFE3E8',
      },
      head: {
        fontWeight: 'bold',
        textWrap: 'nowrap',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({theme}) => ({
        backgroundColor: theme.palette.secondary.light,
      }),
    },
  },
  MuiTablePagination: {
    defaultProps: {
      labelDisplayedRows: ({from, to, count}) => `${from}-${to} z ${count}`,
      labelRowsPerPage: 'Počet řádků na stránku',
    },
  },
  MuiTableRow: {
    styleOverrides: {
      hover: {
        cursor: 'pointer',
      },
    },
  },
  MuiTableSortLabel: {
    defaultProps: {
      IconComponent: ArrowDropDownIcon,
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'unset',
        fontWeight: '600',
        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: ({ownerState, theme}) => ({
        '& .Mui-selected': {
          color: `${theme.palette.text.primary} !important`,
        },
        ...(ownerState.variant === 'standard' && {
          height: '57px',
          '& .Mui-selected': {
            color: `${theme.palette.primary.contrastText} !important`,
          },
          '& .MuiTabs-flexContainer': {
            height: '100%',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.contrastText,
          },
        }),
      }),
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: '57px',
      },
    },
  },
} satisfies Theme['components']
